<template>
  <v-container class="fill-height" fluid data-cy="reset-password-page">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <OnboardingConfirm :token="token" :email="email" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OnboardingConfirm from "@/modules/auth/components/OnboardingConfirm.vue";

export default {
  name: "OnboardingConfirmPage",
  components: {
    OnboardingConfirm,
  },
  computed: {
    token() {
      let token = "";
      if (this.$route.query && this.$route.query.token) {
        token = this.$route.query.token.toString();
      }
      return token;
    },
    email() {
      let email = "";
      if (this.$route.query && this.$route.query.email) {
        email = this.$route.query.email.toString();
      }
      return email;
    },
  },
};
</script>
